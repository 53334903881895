/* eslint-disable */
import React, {Component} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core'
import {injectIntl, FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Slider from "react-slick";
import IsDesktop from '../../common/IsDesktop'
import IsMobile from '../../common/IsMobile'


import Icon from '../Icon'


import Promo5 from '../../../assets/packs-images/promo5.png'
import Promo5Fr from '../../../assets/packs-images/defaultimagefr.png'

import GiftOffer from '../../../assets/images/diapers/gift-offer.png'

import '../../../assets/css/slick.css'
import sendEvent from "../../../helpers/sendAnalytics";

const ToggleCSS = css`
  ${tw`fixed pin-b cursor-pointer font-montserrat text-lg text-white px-8 py-6 inline-block uppercase`}
  background-color: #4D8DCD;
  padding-right: 2rem;
  letter-spacing: 0;
  z-index: 999;
  left: 200px;
  bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-right: 20px;
  }
  @media screen and (max-width: 1023px) {
    background: #4D8DCD;
    padding: 15px;
    right: 0;
    left: 0;
  }

  @media screen and (max-width: 340px) {
    font-size: 16px;
  }
`

const Toggle = styled.div`
  ${ToggleCSS}
  background-color: ${props => props.diapersOffer ? '#00B4DD' : '#4D8DCD'};
  transition: 0;
  transform: translateY(${props => props.open ? '-280px' : '0px'});
  font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
  svg {
    transition: 0.2s;
    transform: rotate(${props => !props.open ? '180deg' : '0deg'});
  }
  @media screen and (max-width: 1023px) {
    background-color: ${props => props.diapersOffer ? '#00B4DD' : '#4D8DCD'};
    width: 100%;
    left: 0;
    transform: translateY(${props => props.open ? '-250px' : '0px'});
  }
`

const ToggleWrapper = styled.div`
  ${tw`fixed`}
  height: 280px;
  bottom: 0;
  width: 100%;
  transition: 0;
  z-index: 999;
  display:flex;
  align-items:flex-end;
  transform: translateY(${props => props.open ? '0px' : '335px'});
  background-color: ${props => props.diapersOffer ? '#00B4DD' : 'white'};
  .diapers-offer{
    height: 280px;
    width: 80%;
    margin: 0 auto 0 200px;
    display:flex;
  }
  .diapers-offer-text{
    display:flex;
    align-items:center;
    img{
      height: 80%;
    }
    span{
      padding-left: 20px;
      font-size:1.75rem;
      color: white;
      font-family: "Montserrat", sans-serif;
      width: 55%;
    }
  }
  .diapers-offer-button{
    display:flex;
    align-items:center;
    justify-content:center;
    a{
      background-color: transparent;
      border: 2px solid white;
      color: white;
      border-radius: 30px;
      text-decoration: none;
      width:140px;
      text-align:center;
      padding: 11px;
      font-family: "Montserrat", sans-serif;
    }
}

  @media screen and (max-width: 1023px) {
    height: 250px;
    width:100%;
    transform: translateY(${props => props.open ? '0px' : '250px'});
    display: block;
    .diapers-offer{
      margin: 0 auto;
      padding: 25px 5% 0 5%;
      flex-direction: column;
      align-items:center;
      img{
        height: 85px;
      }
      .diapers-offer-text span{
        width: 100%;
        font-size: 1.5rem;
        padding-bottom:25px;
      }
    }
  }
`

const PromoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center
`

const PromoCard = styled.div`
  width: 394px !important;
  height: 236px !important;
  display: flex !important;
  align-items: center !important;
  background-color: white;
  box-shadow: 0px 0px 15px #e0e0e0;
  margin: 0 10px;
  padding: 0 10px;
  a { flex: 1; }
  .pic {
    width: 40%;
    height: auto;
  }
  .content {
    width: 55%;
    margin-left: 5%;
    font-family: Montserrat, sans-serif;
    .title {
      font-size: 16px;
      font-weight: 500;
    }
    .subcontent {
      font-size: 12px;
    }
    .promo-button {
      display: inline-block;
      font-size: 12px;
      margin-top: 10px;
      padding: 5px 20px;
      color: #5375B4;
      border: 2px solid #5375B4;
      border-radius: 30px;
      text-decoration: none;
      &:focus {
        outline: 2px solid black;
      }
    }
  }
  @media screen and (max-width: 450px) {
    width: 300px  !important;
  }
`

class Offers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOffers: false,
      render: false
    };
    this.toggleOffers = this.toggleOffers.bind(this);
  }

  componentDidMount(){
    /*let hideOffers = sessionStorage.getItem('hide_offers')
    if(hideOffers){
      this.setState({showOffers: false})
    } else {
      sessionStorage.setItem('hide_offers',true)
    }*/
    this.setState({render: true});
  }

  toggleOffers(){
    this.setState(prevState => ({
      showOffers: !prevState.showOffers
    }));
  }

  hideOffers(){
    this.setState(prevState => ({
      showOffers: false
    }));
  }

  render() {

    const { intl, diapersOffer } = this.props;
    const { showOffers, slide } = this.state
    const langCode = intl.locale == 'fr' ? 'FR':'EN'

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      accessibility: true,
      autoplay:false,
      pauseOnHover:true,
      pauseOnFocus:true,
      arrows: true,
      responsive:[
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            fade:true,
          }
        }
      ]
    };
    if (this.state.render == true) {
      return (
        <React.Fragment>
          <div
            className="blackoverlay"
            onClick={() => this.hideOffers()}
            style={{opacity: showOffers ? 0.8 : 0, pointerEvents: showOffers ? 'all' : 'none'}}
          ></div>
          <Toggle diapersOffer={diapersOffer} open={showOffers} onClick={this.toggleOffers}>
            <FormattedMessage id={diapersOffer ? "diapers.offers":"offers"}/>
            <Icon icon="chevron" down strokeWidth={3} size={24} stroke="#FFFFFF"/>
          </Toggle>
          <ToggleWrapper diapersOffer={diapersOffer} open={showOffers}>

            <IsDesktop>
              { diapersOffer ?
                <div className="diapers-offer">
                  <div className="diapers-offer-text">
                    <img src={GiftOffer} alt="yellow giftbox with red ribbon" />
                    <FormattedMessage id="diapers.offers-text"/>
                  </div>
                  <div className="diapers-offer-button">
                  <a href='#signup' onClick={() => this.hideOffers()}><FormattedMessage id="diapers.offers-button"/></a>
                  </div>
                </div>
                :
                <PromoWrapper id="promo-slider">
                <div className="whiteshadow"/>
                <Slider className='nomleft'  {...settings}>
                  {/* <PromoCard key="promo7" id="promo3"> */}
                  {/*   <img src={intl.locale == 'fr' ? Promo7Fr:Promo7} className="pic"/> */}
                  {/*   <div className="content"> */}
                  {/*     <FormattedMessage id="promos.promo7Title"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <FormattedMessage id="promos.promo7Content"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <a id="promo-link7" */}
                  {/*        href={"https://share.promotionpod.com/share/2312e51db1a42f0d264d1daa0cea302c780804f6?utm_medium=OfferDrawer&utm_source=Website&utm_campaign=TT_2023&utm_target="+langCode+"&utm_content=Q1"} */}
                  {/*        className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_6'}})}>{intl.locale == 'fr' ? "Économiser 1,50 $" : "Save $1.50"}</a> */}
                  {/*   </div> */}
                  {/* </PromoCard> */}
                  {/* <PromoCard key="promo8" id="promo8"> */}
                  {/*   <img src={intl.locale == 'fr' ? Promo8Fr:Promo8} style={{padding:'8px'}} className="pic"/> */}
                  {/*   <div className="content"> */}
                  {/*     <FormattedMessage id="promos.promo14Title"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <FormattedMessage id="promos.promo14Content"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <a id="promo-link7" */}
                  {/*        href={"https://share.promotionpod.com/share/2476087a97e137ddc3b29331510d153dd76ea7f0/?utm_medium=website&utm_source=offerdrawer&utm_campaign=originalpi&utm_target="+intl.locale+"&utm_content=coupon"} */}
                  {/*        className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'drawer_PI_'+langCode}})}>{intl.locale == 'fr' ? "Économiser 1 $" : "Save $1"}</a> */}
                  {/*   </div> */}
                  {/* </PromoCard> */}
                  {/* <PromoCard key="promo9" id="promo9"> */}
                  {/*   <img src={Promo9} style={{padding:'8px'}} className="pic"/> */}
                  {/*   <div className="content"> */}
                  {/*     <FormattedMessage id="promos.promo15Title"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <FormattedMessage id="promos.promo15Content"> */}
                  {/*       {(txt) => ( */}
                  {/*         <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                  {/*       )} */}
                  {/*     </FormattedMessage> */}
                  {/*     <a id="promo-link7" */}
                  {/*        href={"https://royale.websaver.ca/"+intl.locale+"/client/Royale_Facial_Tissue_May_Allergy_Campaign?utm_medium=website&utm_source=offerdrawer&utm_campaign=facialallergy&utm_target="+intl.locale+"&utm_content=coupon"} */}
                  {/*        className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_ALLERGY_'+langCode}})}>{intl.locale == 'fr' ? "Économiser 1,50 $" : "Save $1.50"}</a> */}
                  {/*   </div> */}
                  {/* </PromoCard> */}
                  <PromoCard key="promo5" id="promo5">
                    <img src={intl.locale == 'fr' ? Promo5Fr : Promo5} className="pic"/>
                    <div className="content">
                      <FormattedMessage id="promos.promo5Title">
                        {(txt) => (
                          <p className="title" dangerouslySetInnerHTML={{__html: txt}}/>
                        )}
                      </FormattedMessage>
                      <FormattedMessage id="promos.promo5Content">
                        {(txt) => (
                          <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/>
                        )}
                      </FormattedMessage>
                      <a id="promo-link5" href="#footer" onClick={() => this.hideOffers()}
                         className="promo-button" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_SUB'}})}>{intl.locale == 'fr' ? "Adhérer" : "Subscribe"}</a>
                    </div>
                  </PromoCard>
                </Slider>
              </PromoWrapper>
            }

            </IsDesktop>
            <IsMobile>
            { diapersOffer ?
                <div className="diapers-offer">
                  <div className="diapers-offer-text">
                    <img src={GiftOffer} alt="yellow giftbox with red ribbon" />
                    <FormattedMessage id="diapers.offers-text"/>
                  </div>
                  <div className="diapers-offer-button">
                  <a href='#signup' onClick={() => this.hideOffers()}><FormattedMessage id="diapers.offers-button"/></a>
                  </div>
                </div>
                :
              <PromoWrapper id="promo-slider">
                <div className="whiteshadow"/>
                <Slider className='nomleft' {...settings}>
                {/* <PromoCard key="promo7" id="promo7"> */}
                {/*   <img src={intl.locale == 'fr' ? Promo7Fr:Promo7} className="pic"/> */}
                {/*   <div className="content"> */}
                {/*     <FormattedMessage id="promos.promo7Title"> */}
                {/*       {(txt) => ( */}
                {/*         <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*       )} */}
                {/*     </FormattedMessage> */}
                {/*     <FormattedMessage id="promos.promo7Content"> */}
                {/*       {(txt) => ( */}
                {/*         <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*       )} */}
                {/*     </FormattedMessage> */}
                {/*     <a id="promo-link7" */}
                {/*        href={"https://share.promotionpod.com/share/2312e51db1a42f0d264d1daa0cea302c780804f6?utm_medium=OfferDrawer&utm_source=Website&utm_campaign=TT_2023&utm_target="+langCode+"&utm_content=Q1"} */}
                {/*        className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_6'}})}>{intl.locale == 'fr' ? "Économiser 1,50 $" : "Save $1.50"}</a> */}
                {/*   </div> */}
                {/* </PromoCard> */}
                {/* <PromoCard key="promo8" id="promo8"> */}
                {/*     <img src={intl.locale == 'fr' ? Promo8Fr:Promo8} style={{padding:'8px'}} className="pic"/> */}
                {/*     <div className="content"> */}
                {/*       <FormattedMessage id="promos.promo14Title"> */}
                {/*         {(txt) => ( */}
                {/*           <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*         )} */}
                {/*       </FormattedMessage> */}
                {/*       <FormattedMessage id="promos.promo14Content"> */}
                {/*         {(txt) => ( */}
                {/*           <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*         )} */}
                {/*       </FormattedMessage> */}
                {/*       <a id="promo-link7" */}
                {/*          href={"https://share.promotionpod.com/share/2476087a97e137ddc3b29331510d153dd76ea7f0/?utm_medium=website&utm_source=offerdrawer&utm_campaign=originalpi&utm_target="+intl.locale+"&utm_content=coupon"} */}
                {/*          className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'drawer_PI_'+langCode}})}>{intl.locale == 'fr' ? "Économiser 1 $" : "Save $1"}</a> */}
                {/*     </div> */}
                {/*   </PromoCard> */}
                {/* <PromoCard key="promo9" id="promo9"> */}
                {/*   <img src={Promo9} style={{padding:'8px'}} className="pic"/> */}
                {/*   <div className="content"> */}
                {/*     <FormattedMessage id="promos.promo15Title"> */}
                {/*       {(txt) => ( */}
                {/*         <p className="title" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*       )} */}
                {/*     </FormattedMessage> */}
                {/*     <FormattedMessage id="promos.promo15Content"> */}
                {/*       {(txt) => ( */}
                {/*         <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/> */}
                {/*       )} */}
                {/*     </FormattedMessage> */}
                {/*     <a id="promo-link7" */}
                {/*        href={"https://royale.websaver.ca/"+intl.locale+"/client/Royale_Facial_Tissue_May_Allergy_Campaign?utm_medium=website&utm_source=offerdrawer&utm_campaign=facialallergy&utm_target="+intl.locale+"&utm_content=coupon"} */}
                {/*        className="promo-button" target="_blank" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_ALLERGY_'+langCode}})}>{intl.locale == 'fr' ? "Économiser 1,50 $" : "Save $1.50"}</a> */}
                {/*   </div> */}
                {/* </PromoCard> */}
                <PromoCard key="promo5">
                  <img src={intl.locale == 'fr' ? Promo5Fr : Promo5} alt="Offer" className="pic"/>
                  <div className="content">
                    <FormattedMessage id="promos.promo5Title">
                      {(txt) => (
                        <p className="title" dangerouslySetInnerHTML={{__html: txt}}/>
                      )}
                    </FormattedMessage>
                    <FormattedMessage id="promos.promo5Content">
                      {(txt) => (
                        <p className="subcontent" dangerouslySetInnerHTML={{__html: txt}}/>
                      )}
                    </FormattedMessage>
                    <a id="promo-link5" href="#footer" onClick={() => this.hideOffers()}
                       className="promo-button" onClick={() => sendEvent({ ga: { category: 'Engagement', action: 'Click', label: 'OFFER_DRAWER_SUB'}})}>{intl.locale == 'fr' ? "Adhérer" : "Subscribe"}</a>
                  </div>
                </PromoCard>
                </Slider>
              </PromoWrapper>
            }
            </IsMobile>

          </ToggleWrapper>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(Offers)
